import { getHomeTableInfo, createHomeTable, updateHomeTable, deleteHomeTable, getHomeTable } from '../../config/configSP'
import { toast } from 'react-toastify'

export const HOMETABLE_FORM_RESET = 'HOMETABLE_FORM_RESET';
export const HOMETABLE_FORM_FIND = 'HOMETABLE_FORM_FIND';
export const HOMETABLE_FORM_CREATE = 'USERSSP_FORM_CREATE';
export const HOMETABLE_FORM_UPDATE = 'USERSSP_FORM_UPDATE';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return JSON.stringify(data.message);
  }

  return error.message || 'ERROR';
}

const actions = {
  doNew: () => {
    return {
      type: HOMETABLE_FORM_RESET,
    };
  },

  doFind: (id, history) => async (dispatch) => {
      try {
        await getHomeTable({'tableID': id}).then(res => {
          const record = res.data[0];
  
          dispatch({
            type: HOMETABLE_FORM_FIND,
            payload: record,
          });
        })
      } catch (error) {
        toast.error(selectErrorMessage(error))
        history.push('/admin/smartpunting/hometable');
      }
  },
 
  doCreate: (values, history) => async (dispatch) => {
    try {
      await createHomeTable({data: values}).then(res => {

        dispatch({
          type: HOMETABLE_FORM_CREATE,
        });
        toast.success('HOME TABLE created successfully');
        history.push('/admin/smartpunting/hometable');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },

  doUpdate: (id, values, history) => async (
    dispatch,
    getState,
  ) => {
    try {
      await updateHomeTable({'tableID': id, data: values}).then(res => {
        dispatch({
          type: HOMETABLE_FORM_UPDATE,
        })
        toast.success('HOME TABLE updated');
        history.push('/admin/smartpunting/hometable');
      })     
    } catch (error) {
      toast.error(selectErrorMessage(error))
    }
  },
};

export default actions;
