import { USERS_LIST_FETCH_STARTED, USERS_LIST_FETCH_SUCCESS, USERS_LIST_FETCH_ERROR, USERS_LIST_DELETE_STARTED, USERS_LIST_DELETE_SUCCESS,
          USERS_LIST_DELETE_ERROR, USERS_LIST_OPEN_CONFIRM, USERS_LIST_CLOSE_CONFIRM, USERS_DATE_FETCH_STARTED, USERS_DATE_FETCH_SUCCESS,
          USERS_DATE_FETCH_ERROR } from '../actions/usersListActions';


const defaultState = {
  rows: [],
  loading: false,
  loadingRegisteredDate: false,
  registeredUserDate: []
};

function userReducer(state = defaultState, action) {
  switch (action.type) {
    case USERS_LIST_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case USERS_LIST_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: action.payload.rows,
      });
    case USERS_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: [],
      });
    case USERS_LIST_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case USERS_LIST_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case USERS_LIST_DELETE_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case USERS_LIST_OPEN_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: true,
        idToDelete: action.payload.id
      });
    case USERS_LIST_CLOSE_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case USERS_DATE_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loadingRegisteredDate: true,
      });
    case USERS_DATE_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loadingRegisteredDate: false,
        registeredUserDate: action.payload.rows
      });
    case USERS_DATE_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loadingRegisteredDate: false,
        registeredUserDate: []
      });
    default:
      return state;
  }
}

export default userReducer;