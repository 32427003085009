import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Alert, Button, FormGroup, Label, InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import Widget from '../../components/Widget';

// import { loginUser } from '../../redux/actions/auth';
import './login.css'
import loginUser from '../../redux/actions/auth';

class Login extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        };

        this.doLogin = this.doLogin.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    doLogin(e) {
        e.preventDefault();
        this.props.dispatch(loginUser({ email: this.state.email, password: this.state.password }, this.props.history));
    }

    changeEmail(event) {

        this.setState({ email: event.target.value });
    }

    changePassword(event) {
        // console.log(event.target.value)
        this.setState({ password: event.target.value });
    }

    render() {
        const { from } = { from: { pathname: '/admin' } };
        // cant access login page while logged in
        if (this.props.currentUser) {
            return (
                <Redirect to={from} />
            );
        }

        return (
            <div className="auth-page">
                <Container>
                    <Widget className="widget-auth mx-auto last" title={<h3 className="mt-0">Login to PTP Admin Portal</h3>}>
                        <p className="widget-auth-info">
                            Use your email to sign in.
                        </p>


                        <form onSubmit={this.doLogin}>
                            {
                                this.props.errorMessage ? (
                                    <Alert className="alert-sm widget-middle-overflow rounded-0" color="danger">
                                        {this.props.errorMessage}
                                    </Alert>
                                ) : null
                            }
                            <FormGroup className="mt">
                                <Label for="email">Email</Label>
                                <InputGroup className="input-group-no-border">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="la la-user text-white" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input id="email" className="input-transparent pl-3" value={this.state.email} onChange={this.changeEmail} type="email"
                                        required name="email" placeholder="Email" />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <InputGroup className="input-group-no-border">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="la la-lock text-white" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input id="password" className="input-transparent pl-3" value={this.state.password}
                                        onChange={this.changePassword} type="password"
                                        required name="password" placeholder="Password" />
                                </InputGroup>
                            </FormGroup>
                            <div className="auth-widget-footer">
                                <Button type="submit" color="danger" className="auth-btn" size="lg">
                                    {this.props.isFetching ? 'Loading...' : 'Login'}
                                </Button>
                            </div>

                        </form>

                    </Widget>
                </Container>
                <footer className="auth-footer">
                    {new Date().getFullYear()} &copy; Past The Post.
                </footer>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isFetching: state.auth.isFetching,
    currentUser: state.auth.currentUser,
    errorMessage: state.auth.errorMessage,
});

export default withRouter(connect(mapStateToProps)(Login));

