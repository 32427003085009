import { toast } from "react-toastify";
import {
  getUsersAnalytics,
  emptyAAP,
  uploadRace,
  retrieveLoadedVenues,
  getJockeyPremiership,
  getJockey,
  createJockey,
  updateJockey,
  deleteJockey,
} from "../../config/config";

export const RECEIVED_DATA_SUCCESS =
  "RECEIVED_DATA_SUCCESS"; /* this action already exists watch out */
export const RECEIVING_DATA = "RECEIVING_DATA";
export const EMPTY_AAP = "EMPTY_AAP";
export const UPLOAD_RACE = "UPLOAD_RACE";
export const RETRIEVE_LOADED_VENUES = "RETRIEVE_LOADED_VENUES";
export const GET_JOCKEY_PREMIERSHIP = "GET_JOCKEY_PREMIERSHIP";

export const JOCKEY_FORM_FIND_STARTED = "JOCKEY_FORM_FIND_STARTED";
export const JOCKEY_FORM_FIND_SUCCESS = "JOCKEY_FORM_FIND_SUCCESS";
export const JOCKEY_FORM_FIND_ERROR = "JOCKEY_FORM_FIND_ERROR";

export const JOCKEY_FORM_CREATE_STARTED = "JOCKEY_FORM_CREATE_STARTED";
export const JOCKEY_FORM_CREATE_SUCCESS = "JOCKEY_FORM_CREATE_SUCCESS";
export const JOCKEY_FORM_CREATE_ERROR = "JOCKEY_FORM_CREATE_ERROR";

export const JOCKEY_FORM_UPDATE_STARTED = "JOCKEY_FORM_UPDATE_STARTED";
export const JOCKEY_FORM_UPDATE_SUCCESS = "JOCKEY_FORM_UPDATE_SUCCESS";
export const JOCKEY_FORM_UPDATE_ERROR = "JOCKEY_FORM_UPDATE_ERROR";

export const JOCKEY_FORM_DELETE_STARTED = "JOCKEY_FORM_DELETE_STARTED";
export const JOCKEY_FORM_DELETE_SUCCESS = "JOCKEY_FORM_DELETE_SUCCESS";
export const JOCKEY_FORM_DELETE_ERROR = "JOCKEY_FORM_DELETE_ERROR";

export function receiveDataRequest() {
  return (dispatch) => {
    dispatch(receivingData());
    getUsersAnalytics().then((res) => {
      dispatch(receiveDataSuccess(res));
    });
  };
}

export function receiveDataSuccess(payload) {
  return {
    type: RECEIVED_DATA_SUCCESS,
    payload,
  };
}

export function receivingData() {
  return {
    type: RECEIVING_DATA,
  };
}

export function emptyAAPFunc() {
  return (dispatch) => {
    dispatch(EMPTY_AAP());
    emptyAAP().then((res) => {
      dispatch(receiveDataSuccess(res));
    });
  };
}

export function uploadRaceFunc() {
  return (dispatch) => {
    dispatch(UPLOAD_RACE());
    uploadRace().then((res) => {
      dispatch(receiveDataSuccess(res));
    });
  };
}
export function retriveLoadedVenuesFunc() {
  return (dispatch) => {
    dispatch(RETRIEVE_LOADED_VENUES());
    retrieveLoadedVenues().then((res) => {
      dispatch(receiveDataSuccess(res));
    });
  };
}

const actions = {
  emptyAAPAction: () => async (dispatch) => {
    try {
      dispatch({
        type: EMPTY_AAP,
      });
      await emptyAAP();
    } catch (error) {}
  },
  getJockeyPremiership: () => async (dispatch) => {
    try {
      let response = await getJockeyPremiership();
      dispatch({
        type: GET_JOCKEY_PREMIERSHIP,
        payload: {
          data: response,
        },
      });
    } catch (error) {}
  },
  doFindJockey: (id, history) => async (dispatch) => {
    try {
      dispatch({
        type: JOCKEY_FORM_FIND_STARTED,
      });

      await getJockey({ id: id }).then((res) => {
        const record = res[0];
        dispatch({
          type: JOCKEY_FORM_FIND_SUCCESS,
          payload: record,
        });
      });
    } catch (error) {
      toast.error("Error retrieving Jockey");
      dispatch({
        type: JOCKEY_FORM_FIND_ERROR,
      });
    }
  },

  doCreateJockey: (values, history) => async (dispatch) => {
    try {
      dispatch({
        type: JOCKEY_FORM_CREATE_STARTED,
      });
      await createJockey(values).then((res) => {
        dispatch({
          type: JOCKEY_FORM_CREATE_SUCCESS,
        });
        toast.success("Jockey Premiership created successfully");
        history.push("/admin/jockeyPremiership");
      });
    } catch (error) {
      toast.error("Error creating jockey premiership");
      dispatch({
        type: JOCKEY_FORM_CREATE_ERROR,
      });
    }
  },

  doUpdateJockey: (id, values, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: JOCKEY_FORM_UPDATE_STARTED,
      });
      updateJockey({ id: id, data: values }).then((res) => {
        dispatch({
          type: JOCKEY_FORM_UPDATE_SUCCESS,
        });
        toast.success("Jockey Premiership updated");
        history.push("/admin/jockeyPremiership");
      });
    } catch (error) {
      toast.error("Error updating Jockey premiership");

      dispatch({
        type: JOCKEY_FORM_UPDATE_ERROR,
      });
    }
  },
  doDeleteJockey: (id, history) => async (dispatch) => {
    try {
      dispatch({
        type: JOCKEY_FORM_DELETE_STARTED,
      });

      await deleteJockey({ id: id }).then((res) => {
        dispatch({
          type: JOCKEY_FORM_DELETE_SUCCESS,
        });
        getJockeyPremiership().then((response) => {
          dispatch({
            type: GET_JOCKEY_PREMIERSHIP,
            payload: {
              data: response,
            },
          });
        });
        toast.success("Jockey DELETED.");
      });
    } catch (error) {
      toast.error("Error Deleting Jockey Premiership");

      dispatch({
        type: JOCKEY_FORM_DELETE_ERROR,
      });
    }
  },
};

export default actions;
