import {
  HOMETABLE_FORM_RESET, HOMETABLE_FORM_FIND, HOMETABLE_FORM_CREATE, HOMETABLE_FORM_UPDATE,
} from '../actionsSP/homeTableFormActions';

const defaultState = {
  findLoading: false,
  saveLoading: false,
  record: null,
};

function homeTableFormReducersSP(state = defaultState, action) {
  switch (action.type) {
    case HOMETABLE_FORM_RESET:
      return Object.assign({}, state, {
        ...defaultState,
      });
    case HOMETABLE_FORM_FIND:
      return Object.assign({}, state, {
        ...state,
        record: action.payload,
        findLoading: false,
      });
    case HOMETABLE_FORM_CREATE:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case HOMETABLE_FORM_UPDATE:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    default:
      return state;
  }
};


export default homeTableFormReducersSP;