import { ON_NOW_STARTED, ON_NOW_SUCCESS, ON_NOW_ERROR } from '../actions/onNowActions';
  
  const initialState = {
    onNowLoading: false,
    selectedRace: [],
    nextRaces : [],
    next5Races: [],
    };
  
  function onNowReducer(state = initialState, action){
      switch (action.type) {
        case ON_NOW_STARTED:
              return Object.assign({}, state, {
                onNowLoading: true,
              });

        case ON_NOW_SUCCESS:
              return Object.assign({}, state, {
                onNowLoading: false,
                selectedRace: action.payload.selectedRace,
                nextRaces: action.payload.nextRaces,
                next5Races: action.payload.next5Races,
              });

        case ON_NOW_ERROR:
              return Object.assign({}, state, {
                onNowLoading: false,
                selectedRace: [],
                nextRaces: [],
                next5Races: [],
              });
        default:
          return state;
      }
    }
    
  export default onNowReducer;
  
  
  