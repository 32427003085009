import { toast } from 'react-toastify';
import { getSelectedOnNow, updateOnNowRaceID } from '../../config/config'

export const ON_NOW_STARTED = 'ON_NOW_STARTED'
export const ON_NOW_SUCCESS = 'ON_NOW_SUCCESS'
export const ON_NOW_ERROR = 'ON_NOW_ERROR'

export const actions = {

    getSelectedOnNowRace: () => async (dispatch) => {
        try {
          dispatch({
            type: ON_NOW_STARTED,
          });
          let response = await getSelectedOnNow()
          dispatch({
            type: ON_NOW_SUCCESS,
            payload: {
                selectedRace: response.selectedRace,
                nextRaces: response.nextRaces,
                next5Races: response.next5Races,
              },
          });
        } catch (error) {
          toast.error('ERROR GETTING ON NOW RACE SELECTION')
          dispatch({
            type: ON_NOW_ERROR,
          });
        }
    },

    updateOnNowRace:(data) => async (dispatch) => {
      try {
        dispatch({
          type: ON_NOW_STARTED,
        });
        let response = await updateOnNowRaceID(data)
        dispatch({
          type: ON_NOW_SUCCESS,
          payload: {
              selectedRace: response.selectedRace,
              nextRaces: response.nextRaces,
              next5Races: response.next5Races,
            },
        });
      } catch (error) {
        toast.error('ERROR GETTING ON NOW RACE SELECTION')
        dispatch({
          type: ON_NOW_ERROR,
        });
      }
  },
}

