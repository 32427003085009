import {
  ADVSEL_FORM_RESET, ADVSEL_FORM_FIND_SUCCESS, ADVSEL_FORM_FIND_STARTED, ADVSEL_FORM_FIND_ERROR, ADVSEL_FORM_CREATE_STARTED,
  ADVSEL_FORM_CREATE_SUCCESS, ADVSEL_FORM_CREATE_ERROR, ADVSEL_FORM_UPDATE_STARTED, ADVSEL_FORM_UPDATE_SUCCESS,
  ADVSEL_FORM_UPDATE_ERROR
} from '../actions/advSelFormActions';

const defaultState = {
  findLoading: false,
  saveLoading: false,
  record: null,
};

function advSelFormReducer(state = defaultState, action) {
  switch (action.type) {
    case ADVSEL_FORM_RESET:
      return Object.assign({}, state, {
        ...defaultState,
      });
    case ADVSEL_FORM_FIND_STARTED:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: true,
      });
    case ADVSEL_FORM_FIND_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        record: action.payload,
        findLoading: false,
      });
    case ADVSEL_FORM_FIND_ERROR:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: false,
      });
    case ADVSEL_FORM_CREATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case ADVSEL_FORM_CREATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case ADVSEL_FORM_CREATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case ADVSEL_FORM_UPDATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case ADVSEL_FORM_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case ADVSEL_FORM_UPDATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    default:
      return state;
  }
};


export default advSelFormReducer;