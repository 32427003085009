import {
  getUser,
  createUser,
  updateUser,
  getUserActivity,
} from "../../config/configSP";
import { toast } from "react-toastify";

export const USERSSP_FORM_RESET = "USERSSP_FORM_RESET";
export const USERSSP_FORM_FIND_SUCCESS = "USERSSP_FORM_FIND_SUCCESS";
export const USERSSP_FORM_FIND_SECOND_SUCCESS =
  "USERSSP_FORM_FIND_SECOND_SUCCESS";
export const USERSSP_FORM_FIND_STARTED = "USERSSP_FORM_FIND_STARTED";
export const USERSSP_FORM_FIND_ERROR = "USERSSP_FORM_FIND_ERROR";
export const USERSSP_FORM_CREATE_STARTED = "USERSSP_FORM_CREATE_STARTED";
export const USERSSP_FORM_CREATE_SUCCESS = "USERSSP_FORM_CREATE_SUCCESS";
export const USERSSP_FORM_CREATE_ERROR = "USERSSP_FORM_CREATE_ERROR";
export const USERSSP_FORM_UPDATE_STARTED = "USERSSP_FORM_UPDATE_STARTED";
export const USERSSP_FORM_UPDATE_SUCCESS = "USERSSP_FORM_UPDATE_SUCCESS";
export const USERSSP_FORM_UPDATE_ERROR = "USERSSP_FORM_UPDATE_ERROR";
export const USERSSP_PASSWORD_UPDATE_SUCCESS =
  "USERSSP_PASSWORD_UPDATE_SUCCESS";

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return JSON.stringify(data.message);
  }

  return error.message || "ERROR";
}

const actions = {
  doNew: () => {
    return {
      type: USERSSP_FORM_RESET,
    };
  },

  doFind: (id, history) => async (dispatch) => {
    try {
      dispatch({
        type: USERSSP_FORM_FIND_STARTED,
      });

      await getUser({ client_id: id }).then((res) => {
        const record = res.data[0];
        dispatch({
          type: USERSSP_FORM_FIND_SUCCESS,
          payload: record,
        });
      });
      await getUserActivity({ client_id: id }).then((res) => {
        const recordA = res?.data;
        dispatch({
          type: USERSSP_FORM_FIND_SECOND_SUCCESS,
          payload: recordA,
        });
      });
    } catch (error) {
      toast.error(selectErrorMessage(error));
      dispatch({
        type: USERSSP_FORM_FIND_ERROR,
      });

      history.push("/admin/smartpunting/users");
    }
  },

  doCreate: (values, history) => async (dispatch) => {
    try {
      dispatch({
        type: USERSSP_FORM_CREATE_STARTED,
      });
      await createUser(values).then((res) => {
        dispatch({
          type: USERSSP_FORM_CREATE_SUCCESS,
        });
        toast.success("User created successfully");
        history.push("/admin/smartpunting/users");
      });
    } catch (error) {
      toast.error(selectErrorMessage(error));
      dispatch({
        type: USERSSP_FORM_CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values, history) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERSSP_FORM_UPDATE_STARTED,
      });
      updateUser({ client_id: id, data: values }).then((res) => {
        dispatch({
          type: USERSSP_FORM_UPDATE_SUCCESS,
        });
        toast.success("User updated");
        history.push("/admin/smartpunting/users");
      });
    } catch (error) {
      toast.error(selectErrorMessage(error));

      dispatch({
        type: USERSSP_FORM_UPDATE_ERROR,
      });
    }
  },
};

export default actions;
