import { API_FETCH_STARTED, API_FETCH_SUCCESS, API_FETCH_ERROR, SB_FETCH_STARTED, SB_FETCH_SUCCESS, SB_FETCH_ERROR, GET_SUBSCRIPTION_PLAN, VENUESTATE_FETCH_STARTED,
    VENUESTATE_FETCH_SUCCESS, VENUESTATE_FETCH_ERROR,
    GET_VENUE_DETAILS_STARTED,GET_VENUE_DETAILS_SUCCESS,GET_VENUE_DETAILS_ERROR ,
    UPDATE_VENUE_DETAILS_STARTED,UPDATE_VENUE_DETAILS_SUCCESS,UPDATE_VENUE_DETAILS_ERROR,
    QUERY_START, QUERY_SUCCESS, QUERY_ERROR,
    PM2_LIST_INSTANCES_START ,
    PM2_LIST_INSTANCES_SUCCESS ,
    PM2_LIST_INSTANCES_ERROR ,
} from '../actions/apiActions';


const defaultState = {
    sbdata: [],
    data: [],
    venuesdata:[],
    venuesdataLoading:false,
    loading: false,
    subscriptionPlan: [],
    venueDetailsData:[],
    venueDetailsDataLoading:false,
    queryResult: '',
    queryLoading: false,
    pm2Instances:[],
    pm2InstancesLoading:false,
};


function apiReducer(state = defaultState, action) {
    switch (action.type) {
        case QUERY_START:
            return Object.assign({}, state, {
                ...state,
                queryLoading: true,
            });
        case QUERY_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                queryLoading: false,
                queryResult: action.payload.data,
            });
        case QUERY_ERROR:
            return Object.assign({}, state, {
                ...state,
                queryLoading: false,
                queryResult: '',
            });

        case API_FETCH_STARTED:
            return Object.assign({}, state, {
                ...state,
                loading: true,
            });
        case API_FETCH_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                data: action.payload.data,
            });
        case API_FETCH_ERROR:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                data: [],
            });

        case SB_FETCH_STARTED:
            return Object.assign({}, state, {
                ...state,
                loading: true,
            });
        case SB_FETCH_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                sbdata: action.payload.data,
            });
        case SB_FETCH_ERROR:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                sbdata: [],
            });
        case GET_SUBSCRIPTION_PLAN:
            return Object.assign({}, state, {
                ...state,
                subscriptionPlan: action.payload.data
            });

            case VENUESTATE_FETCH_STARTED:
            return Object.assign({}, state, {
                ...state,
                venuesdataLoading: true,
            });
        case VENUESTATE_FETCH_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                venuesdataLoading: false,
                venuesdata: action.payload.data,
            });
        case VENUESTATE_FETCH_ERROR:
            return Object.assign({}, state, {
                ...state,
                venuesdata:[],
                venuesdataLoading:false,
            });


            case GET_VENUE_DETAILS_STARTED:
            return Object.assign({}, state, {
                ...state,
                venueDetailsData:[],
                venueDetailsDataLoading: true,
            });
        case GET_VENUE_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                venueDetailsDataLoading: false,
                venueDetailsData: action.payload.data,
            });
        case GET_VENUE_DETAILS_ERROR:
            return Object.assign({}, state, {
                ...state,
                venueDetailsData:[],
                venueDetailsDataLoading:false,
            });


            case PM2_LIST_INSTANCES_START:
                return Object.assign({}, state, {
                    ...state,
                    pm2Instances:[],
                    pm2InstancesLoading: true,
                });
            case PM2_LIST_INSTANCES_SUCCESS:
                return Object.assign({}, state, {
                    ...state,
                    pm2InstancesLoading: false,
                    pm2Instances: action.payload,
                });
            case PM2_LIST_INSTANCES_ERROR:
                return Object.assign({}, state, {
                    ...state,
                    pm2Instances:[],
                    pm2InstancesLoading:false,
                });
        default:
            return state;
    }
}

export default apiReducer;