import { getHomeTableInfo, deleteHomeTable } from '../../config/configSP';
import { toast } from 'react-toastify';
export const HOMETABLE_LIST_FETCH_STARTED = 'HOMETABLE_LIST_FETCH_STARTED';
export const HOMETABLE_LIST_FETCH_SUCCESS = 'HOMETABLE_LIST_FETCH_SUCCESS';
export const HOMETABLE_LIST_FETCH_ERROR = 'HOMETABLE_LIST_FETCH_ERROR';
export const HOMETABLE_LIST_DELETE_STARTED = 'HOMETABLE_LIST_DELETE_STARTED';
export const HOMETABLE_LIST_DELETE_SUCCESS = 'HOMETABLE_LIST_DELETE_SUCCESS';
export const HOMETABLE_LIST_DELETE_ERROR = 'HOMETABLE_LIST_DELETE_ERROR';
export const HOMETABLE_LIST_OPEN_CONFIRM = 'HOMETABLE_LIST_OPEN_CONFIRM';
export const HOMETABLE_LIST_CLOSE_CONFIRM = 'HOMETABLE_LIST_CLOSE_CONFIRM';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: (filter, keepPagination = false) => async (dispatch) => {
    try {
      dispatch({
        type: HOMETABLE_LIST_FETCH_STARTED,
        payload: { filter, keepPagination },
      });

      const response = await getHomeTableInfo();

      dispatch({
        type: HOMETABLE_LIST_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: HOMETABLE_LIST_FETCH_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: HOMETABLE_LIST_DELETE_STARTED,
      });

      await deleteHomeTable({ 'id': id })

      dispatch({
        type: HOMETABLE_LIST_DELETE_SUCCESS,
      });

      const response = await getHomeTableInfo();
      dispatch({
        type: HOMETABLE_LIST_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });

    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: HOMETABLE_LIST_DELETE_ERROR,
      });
    }
  },

  doOpenConfirm: (id) => async (dispatch) => {
    dispatch({
      type: HOMETABLE_LIST_OPEN_CONFIRM,
      payload: {
        id: id
      },
    });
  },

  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: HOMETABLE_LIST_CLOSE_CONFIRM,
    });
  },
};


export default actions;
