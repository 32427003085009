import {
  RETRIEVE_LOADED_VENUES,
  GET_JOCKEY_PREMIERSHIP,
  JOCKEY_FORM_CREATE_ERROR,
  JOCKEY_FORM_CREATE_STARTED,
  JOCKEY_FORM_CREATE_SUCCESS,
  JOCKEY_FORM_DELETE_ERROR,
  JOCKEY_FORM_DELETE_STARTED,
  JOCKEY_FORM_DELETE_SUCCESS,
  JOCKEY_FORM_FIND_ERROR,
  JOCKEY_FORM_FIND_STARTED,
  JOCKEY_FORM_FIND_SUCCESS,
  JOCKEY_FORM_UPDATE_ERROR,
  JOCKEY_FORM_UPDATE_STARTED,
  JOCKEY_FORM_UPDATE_SUCCESS,
} from '../actions/selectionsActions';

const initialState = {
  findLoading: false,
  saveLoading: false,
  venueData: [],
  jockeys: [],
  record: null,
};

function selReducer(state = initialState, action) {
  switch (action.type) {
    case RETRIEVE_LOADED_VENUES:
      return Object.assign({}, state, {
        venueData: action.payload.data
      });
    case GET_JOCKEY_PREMIERSHIP:
      return  Object.assign({}, state, {
        ...state,
        jockeys: action.payload.data,
      });
    case JOCKEY_FORM_FIND_STARTED:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: true,
      });
    case JOCKEY_FORM_FIND_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        record: action.payload,
        findLoading: false,
      });
    case JOCKEY_FORM_FIND_ERROR:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: false,
      });
    case JOCKEY_FORM_CREATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case JOCKEY_FORM_CREATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case JOCKEY_FORM_CREATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case JOCKEY_FORM_UPDATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case JOCKEY_FORM_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case JOCKEY_FORM_UPDATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case JOCKEY_FORM_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case JOCKEY_FORM_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case JOCKEY_FORM_DELETE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    default:
      return state;
  }
}

export default selReducer;
