import { getAdvSel, createAdvSel, updateAdvSel } from '../../config/config'
import { toast } from 'react-toastify'

export const ADVSEL_FORM_RESET = 'ADVSEL_FORM_RESET';
export const ADVSEL_FORM_FIND_SUCCESS = 'ADVSEL_FORM_FIND_SUCCESS';
export const ADVSEL_FORM_FIND_STARTED = 'ADVSEL_FORM_FIND_STARTED';
export const ADVSEL_FORM_FIND_ERROR = 'ADVSEL_FORM_FIND_ERROR';
export const ADVSEL_FORM_CREATE_STARTED = 'ADVSEL_FORM_CREATE_STARTED';
export const ADVSEL_FORM_CREATE_SUCCESS = 'ADVSEL_FORM_CREATE_SUCCESS';
export const ADVSEL_FORM_CREATE_ERROR = 'ADVSEL_FORM_CREATE_ERROR';
export const ADVSEL_FORM_UPDATE_STARTED = 'ADVSEL_FORM_UPDATE_STARTED';
export const ADVSEL_FORM_UPDATE_SUCCESS = 'ADVSEL_FORM_UPDATE_SUCCESS';
export const ADVSEL_FORM_UPDATE_ERROR = 'ADVSEL_FORM_UPDATE_ERROR';
export const ADVSEL_PASSWORD_UPDATE_SUCCESS = 'ADVSEL_PASSWORD_UPDATE_SUCCESS';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return JSON.stringify(data.message);
  }

  return error.message || 'ERROR';
}

const actions = {
  doNew: () => {
    return {
      type: ADVSEL_FORM_RESET,
    };
  },

  doFind: (id, history) => async (dispatch) => {
      try {
        dispatch({
          type: ADVSEL_FORM_FIND_STARTED,
        });
        await getAdvSel({'id': id}).then(res => {
          
          const record = res.data[0];
  
          dispatch({
            type: ADVSEL_FORM_FIND_SUCCESS,
            payload: record,
          });
        })
      } catch (error) {
        toast.error(selectErrorMessage(error))  
        dispatch({
          type: ADVSEL_FORM_FIND_ERROR,
        });
  
        history.push('/admin/advSel');
      }
  },
 
  doCreate: (values, history) => async (dispatch) => {
    try {
      dispatch({
        type: ADVSEL_FORM_CREATE_STARTED,
      });
      await createAdvSel(values).then(res => {

        dispatch({
          type: ADVSEL_FORM_CREATE_SUCCESS,
        });
        toast.success('Adv Sel created successfully');
        history.push('/admin/advSel');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
      dispatch({
        type: ADVSEL_FORM_CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values, history) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: ADVSEL_FORM_UPDATE_STARTED,
      });
      updateAdvSel({'id': id, data: values}).then(res => {
        dispatch({
          type: ADVSEL_FORM_UPDATE_SUCCESS,
        });
        toast.success('Advanced Selection updated');
        history.push('/admin/adv-sel');
      })     
    } catch (error) {
      toast.error(selectErrorMessage(error)) 

      dispatch({
        type: ADVSEL_FORM_UPDATE_ERROR,
      });
    }
  },
};

export default actions;
