import {
    VENUES_ANALYTICS_STARTED, VENUES_ANALYTICS_SUCCESS, VENUES_ANALYTICS_ERROR,
    VENUES_SELECTEDDATE_STARTED, VENUES_SELECTEDDATE_SUCCESS, VENUES_SELECTEDDATE_ERROR,
    VENUE_EDITING_STARTED, VENUE_EDITING_SUCCESS, VENUE_EDITING_ERROR,
    FIND_RACE_INFO_STARTED, FIND_RACE_INFO_SUCCESS, FIND_RACE_INFO_ERROR, LOAD_VENUES,
    LAST_RAIL_POS_STARTED, LAST_RAIL_POS_SUCCESS, LAST_RAIL_POS_ERROR,
    LIST_VENUE_COMMENTS_STARTED, LIST_VENUE_COMMENTS_SUCCESS, LIST_VENUE_COMMENTS_ERROR, LOAD_VENUES_WITH_MAPS
} from '../actions/venuesActions';

const defaultState = {
    trackNames : [],
    venuesSelected: [],
    isLoading : false,
    editReload : false,
    venueInfo : [],
    selectedDate: null,
    selectedVenue: null,
    venueInfoLoading : false,
    RaceInfoLoading: false,
    raceInfo: {},
    horsesInfo: [],
    venues: [],
    railPos: {},
    venuesCmts: [],
};

function venuesReducer(state = defaultState, action) {
    switch (action.type) {
        case VENUES_ANALYTICS_STARTED:
            return Object.assign({}, state, {
                isLoading: true
            });
        case VENUES_ANALYTICS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                trackNames : action.payload.data,
            });
        case VENUES_ANALYTICS_ERROR:
            return Object.assign({}, state, {
                isLoading: false,
                trackNames: [],
            });

        case VENUES_SELECTEDDATE_STARTED:
            return Object.assign({}, state, {
                editReload: true,
            });
        case VENUES_SELECTEDDATE_SUCCESS:
            return Object.assign({}, state, {
                venuesSelected: action.payload.data,
                editReload : false
            });
        case VENUES_SELECTEDDATE_ERROR:
            return Object.assign({}, state, {
                venuesSelected: [],
                editReload: false
            });

        case VENUE_EDITING_STARTED:
            return Object.assign({}, state, {
                venueInfoLoading: true,
            });
        case VENUE_EDITING_SUCCESS:
            return Object.assign({}, state, {
                venueInfo: action.payload.venueInfo,
                selectedDate: action.payload.selectedDate,
                selectedVenue: action.payload.selectedVenue,
                venueInfoLoading : false
            });
        case VENUE_EDITING_ERROR:
            return Object.assign({}, state, {
                venueInfo: [],
                selectedDate: '',
                venueInfoLoading: false
            });
        
        case FIND_RACE_INFO_STARTED:
            return Object.assign({}, state, {
                RaceInfoLoading: true,
            });
        case FIND_RACE_INFO_SUCCESS:
            return Object.assign({}, state, {
                raceInfo: action.payload.raceInfo,
                horsesInfo: action.payload.horsesInfo,
                RaceInfoLoading : false
            });
        case FIND_RACE_INFO_ERROR:
            return Object.assign({}, state, {
                raceInfo: {},
                horsesInfo: [],
                RaceInfoLoading: false
            });
        case LOAD_VENUES:
            return Object.assign({}, state, {
                venues: action.payload.data,
            });
        case LOAD_VENUES_WITH_MAPS:
            return Object.assign({}, state, {
                venues: action.payload.data,
            });
        case LAST_RAIL_POS_STARTED:
            return Object.assign({}, state,{
                isLoading: true,
                railPos: {},
            });
        case LAST_RAIL_POS_SUCCESS:
            return Object.assign({}, state,{
                ...state,
                isLoading: false,
                railPos: action.payload.railPos,
            });
        case LAST_RAIL_POS_ERROR:
            return Object.assign({}, state, {
                railPos: {},
            });
        case LIST_VENUE_COMMENTS_STARTED:
            return Object.assign({}, state,{
                isLoading: true,
                venueCmt: [],
            });
        case LIST_VENUE_COMMENTS_SUCCESS:
            return Object.assign({}, state,{
                ...state,
                isLoading: false,
                venuesCmts: action.payload.venueCmts,
            });
        case LIST_VENUE_COMMENTS_ERROR:
            return Object.assign({}, state,{
                venuesCmts: [],
            })

        default:
            return state;
    }
}

export default venuesReducer;
