import { getLeads, deleteLead, fbLeadss } from "../../config/configSP";
import { toast } from "react-toastify";

export const LEADSSP_LIST_FETCH_STARTED = "LEADSSP_LIST_FETCH_STARTED";
export const LEADSSP_LIST_FETCH_SUCCESS = "LEADSSP_LIST_FETCH_SUCCESS";
export const LEADSSP_LIST_FETCH_ERROR = "LEADSSP_LIST_FETCH_ERROR";
export const LEADSSP_LIST_DELETE_STARTED = "LEADSSP_LIST_DELETE_STARTED";
export const LEADSSP_LIST_DELETE_SUCCESS = "LEADSSP_LIST_DELETE_SUCCESS";
export const LEADSSP_LIST_DELETE_ERROR = "LEADSSP_LIST_DELETE_ERROR";
export const LEADSSP_LIST_OPEN_CONFIRM = "LEADSSP_LIST_OPEN_CONFIRM";
export const LEADSSP_LIST_CLOSE_CONFIRM = "LEADSSP_LIST_CLOSE_CONFIRM";

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || "ERROR";
}

const actions = {
  doFetch:
    (filter, keepPagination = false) =>
      async (dispatch) => {
        try {
          dispatch({
            type: LEADSSP_LIST_FETCH_STARTED,
            payload: { filter, keepPagination },
          });

          const response = await getLeads();

          dispatch({
            type: LEADSSP_LIST_FETCH_SUCCESS,
            payload: {
              rows: response,
            },
          });
        } catch (error) {
          toast.error(selectErrorMessage(error));

          dispatch({
            type: LEADSSP_LIST_FETCH_ERROR,
          });
        }
      },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: LEADSSP_LIST_DELETE_STARTED,
      });

      await deleteLead({
        id: id,
      }); /*add later on to handle if their is an error*/

      dispatch({
        type: LEADSSP_LIST_DELETE_SUCCESS,
      });

      const response = await getLeads();
      // console.log(response)
      dispatch({
        type: LEADSSP_LIST_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error));
      dispatch({
        type: LEADSSP_LIST_DELETE_ERROR,
      });
    }
  },

  doOpenConfirm: (id) => async (dispatch) => {
    // console.log(dispatch);
    dispatch({
      type: LEADSSP_LIST_OPEN_CONFIRM,
      payload: {
        id: id,
      },
    });
  },

  fbleadss: () => async (dispatch) => {
    console.log("Once")
    try {
      await fbLeadss().then(res => {
        toast.success("Lead Emails are sent succesfully")
        toast.dismiss(1)
      })
    } catch (error) {
      toast.error('Error sending lead Emails')
      toast.dismiss(1)
    }
  },

  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: LEADSSP_LIST_CLOSE_CONFIRM,
    });
  },
};

export default actions;
