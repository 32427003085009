import { USERS_FORM_RESET, USERS_FORM_FIND_SUCCESS, USERS_FORM_FIND_STARTED, USERS_FORM_FIND_ERROR, USERS_FORM_CREATE_STARTED,
          USERS_FORM_CREATE_SUCCESS, USERS_FORM_CREATE_ERROR, USERS_FORM_UPDATE_STARTED, USERS_FORM_UPDATE_SUCCESS,
          USERS_FORM_UPDATE_ERROR, UPDATE_INCORRECT_EMAIL,
          UPDATE_PAUSE_ALL, UPDATE_PAUSE_ALL_FALSE, UPDATE_PAUSE_MONDAY, UPDATE_PAUSE_TUESDAY, UPDATE_PAUSE_WEDNESDAY,
          UPDATE_PAUSE_THURSDAY, UPDATE_PAUSE_FRIDAY, UPDATE_PAUSE_SATURDAY, UPDATE_PAUSE_SUNDAY} from '../actions/usersFormActions';

const defaultState = {
  findLoading: false,
  saveLoading: false,
  record: null,
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  incorrectEmail: false,
};

function userFormReducer(state = defaultState, action) {
  switch (action.type) {
    case USERS_FORM_RESET:
      return Object.assign({}, state, {
        ...defaultState,
      });
    case USERS_FORM_FIND_STARTED:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: true,
      });
    case USERS_FORM_FIND_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        record: action.payload,
        findLoading: false,
        sunday: action.sunday,
        monday: action.monday,
        tuesday: action.tuesday,
        wednesday: action.wednesday,
        thursday: action.thursday,
        friday: action.friday,
        saturday: action.saturday,
        incorrectEmail: action.incorrectEmail,
      });
    case USERS_FORM_FIND_ERROR:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: false,
      });
    case USERS_FORM_CREATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case USERS_FORM_CREATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case USERS_FORM_CREATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case USERS_FORM_UPDATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case USERS_FORM_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case USERS_FORM_UPDATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case UPDATE_PAUSE_ALL:
      return Object.assign({}, state, {
        ...state,
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
      });
    case UPDATE_PAUSE_ALL_FALSE:
      return Object.assign({}, state, {
        ...state,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
    case UPDATE_PAUSE_MONDAY:
      return Object.assign({}, state, {
        ...state,
        monday: action.payload,
      });
    case UPDATE_PAUSE_TUESDAY:
      return Object.assign({}, state, {
        ...state,
        tuesday: action.payload,
      });
    case UPDATE_PAUSE_WEDNESDAY:
      return Object.assign({}, state, {
        ...state,
        wednesday: action.payload,
      });
    case UPDATE_PAUSE_THURSDAY:
      return Object.assign({}, state, {
        ...state,
        thursday: action.payload,
      });
    case UPDATE_PAUSE_FRIDAY:
      return Object.assign({}, state, {
        ...state,
        friday: action.payload,
      });
    case UPDATE_PAUSE_SATURDAY:
      return Object.assign({}, state, {
        ...state,
        saturday: action.payload,
      });
    case UPDATE_PAUSE_SUNDAY:
      return Object.assign({}, state, {
        ...state,
        sunday: action.payload,
      });
    case UPDATE_INCORRECT_EMAIL:
      return Object.assign({}, state, {
        ...state,
        incorrectEmail: action.payload,
      });
    default:
      return state;
  }
};


export default userFormReducer;