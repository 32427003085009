import { ADMINS_FORM_RESET, ADMINS_FORM_FIND_SUCCESS, ADMINS_FORM_FIND_STARTED, ADMINS_FORM_FIND_ERROR, ADMINS_FORM_CREATE_STARTED,
          ADMINS_FORM_CREATE_SUCCESS, ADMINS_FORM_CREATE_ERROR, ADMINS_FORM_UPDATE_STARTED, ADMINS_FORM_UPDATE_SUCCESS,
          ADMINS_FORM_UPDATE_ERROR } from '../actions/adminFormActions';
          

const defaultState = {
  findLoading: false,
  saveLoading: false,
  record: null,
};

function adminFormReducer(state = defaultState, action) {
  switch (action.type) {
    case ADMINS_FORM_RESET:
      return Object.assign({}, state, {
        ...defaultState,
      });
    case ADMINS_FORM_FIND_STARTED:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: true,
      });
    case ADMINS_FORM_FIND_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        record: action.payload,
        findLoading: false,
      });
    case ADMINS_FORM_FIND_ERROR:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: false,
      });
    case ADMINS_FORM_CREATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case ADMINS_FORM_CREATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case ADMINS_FORM_CREATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case ADMINS_FORM_UPDATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case ADMINS_FORM_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case ADMINS_FORM_UPDATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    default:
      return state;
  }
};


export default adminFormReducer;