import { getLead } from '../../config/configSP'
import { toast } from 'react-toastify'
// import { getLead, createLead, updateLead} from '../../../server/app/controllersSP/leads.controller';

export const LEADSSP_FORM_RESET = 'LEADSSP_FORM_RESET';
export const LEADSSP_FORM_FIND_SUCCESS = 'LEADSSP_FORM_FIND_SUCCESS';
export const LEADSSP_FORM_FIND_STARTED = 'LEADSSP_FORM_FIND_STARTED';
export const LEADSSP_FORM_FIND_ERROR = 'LEADSSP_FORM_FIND_ERROR';
export const LEADSSP_FORM_CREATE_STARTED = 'LEADSSP_FORM_CREATE_STARTED';
export const LEADSSP_FORM_CREATE_SUCCESS = 'LEADSSP_FORM_CREATE_SUCCESS';
export const LEADSSP_FORM_CREATE_ERROR = 'LEADSSP_FORM_CREATE_ERROR';
export const LEADSSP_FORM_UPDATE_STARTED = 'LEADSSP_FORM_UPDATE_STARTED';
export const LEADSSP_FORM_UPDATE_SUCCESS = 'LEADSSP_FORM_UPDATE_SUCCESS';
export const LEADSSP_FORM_UPDATE_ERROR = 'LEADSSP_FORM_UPDATE_ERROR';
export const LEADSSP_PASSWORD_UPDATE_SUCCESS = 'LEADSSP_PASSWORD_UPDATE_SUCCESS';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return JSON.stringify(data.message);
  }

  return error.message || 'ERROR';
}

const actions = {
  doNew: () => {
    return {
      type: LEADSSP_FORM_RESET,
    };
  },

  doFind: (id, history) => async (dispatch) => {
      try {
        dispatch({
          type: LEADSSP_FORM_FIND_STARTED,
        });
        
        await getLead({'id': id}).then(res => {
          const record = res.data[0];
  
          dispatch({
            type: LEADSSP_FORM_FIND_SUCCESS,
            payload: record,
          });
        })
      } catch (error) {
        toast.error(selectErrorMessage(error))  
        dispatch({
          type: LEADSSP_FORM_FIND_ERROR,
        });
  
        history.push('/admin/smartpunting/leads');
      }
  },
 
//   doCreate: (values, history) => async (dispatch) => {
//     try {
//       dispatch({
//         type: LEADSSP_FORM_CREATE_STARTED,
//       });
//       await createLead(values).then(res => {

//         dispatch({
//           type: LEADSSP_FORM_CREATE_SUCCESS,
//         });
//         toast.success('Lead created successfully');
//         history.push('/admin/smartpunting/leads');
//       })
//     } catch (error) {
//       toast.error(selectErrorMessage(error))
//       dispatch({
//         type: LEADSSP_FORM_CREATE_ERROR,
//       });
//     }
//   },

//   doUpdate: (id, values, history) => async (
//     dispatch,
//     getState,
//   ) => {
//     try {
//       dispatch({
//         type: LEADSSP_FORM_UPDATE_STARTED,
//       });
//       updateLead({'lead_id': id, data: values}).then(res => {
//         dispatch({
//           type: LEADSSP_FORM_UPDATE_SUCCESS,
//         });
//         toast.success('Lead updated');
//         history.push('/admin/smartpunting/leads');
//       })     
//     } catch (error) {
//       toast.error(selectErrorMessage(error)) 

//       dispatch({
//         type: LEADSSP_FORM_UPDATE_ERROR,
//       });
//     }
//   },
};

export default actions;
