import {
  USERS_IP_LIST_FETCH_SUCCESS,
  USERS_IP_LIST_FETCH_STARTED,
  USERS_IP_LIST_FETCH_ERROR,
} from "../actionsSP/usersSPActivityList";

const defaultState = {
  rows: [],
  loading: false,
};

function userIpReducerSP(state = defaultState, action) {
  switch (action.type) {
    case USERS_IP_LIST_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
        rows: [],
      });
    case USERS_IP_LIST_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: action?.payload,
      });
    case USERS_IP_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: [],
      });
    default:
      return state;
  }
}
export default userIpReducerSP;
