import {
  RECEIVED_DATA_SUCCESS,
  RECEIVING_DATA,
  SIGNUPS_FETCH_STARTED,
  SIGNUPS_FETCH_SUCCESS,
  SIGNUPS_FETCH_ERROR,
  SIGNINS_FETCH_STARTED,
  SIGNINS_FETCH_SUCCESS,
  SIGNINS_FETCH_ERROR,
  AFFILIATE_FETCH_STARTED,
  AFFILIATE_FETCH_SUCCESS,
  AFFILIATE_FETCH_ERROR,
  FREQUENT_FETCH_STARTED,
  FREQUENT_FETCH_SUCCESS,
  FREQUENT_FETCH_ERROR,
  Email_FETCH_STARTED,
  Email_FETCH_SUCCESS,
  Email_FETCH_ERROR,
  RECEIVING_MARKETING_STARTED,
  RECEIVING_MARKETING_SUCESS,
  RECEIVING_MARKETING_ERROR,
  ONLINENOW_FETCH_STARTED,
  ONLINENOW_FETCH_SUCCESS,
  ONLINENOW_FETCH_ERROR,
  VISITORORCLIENT_FETCH_STARTED,
  VISITORORCLIENT_FETCH_SUCCESS,
  VISITORORCLIENT_FETCH_ERROR,
  VISITORS_FETCH_STARTED,
  VISITORS_FETCH_SUCCESS,
  VISITORS_FETCH_ERROR,
  CLIENT_AVG_REG_FETCH_STARTED,
  CLIENT_AVG_REG_FETCH_SUCCESS,
  CLIENT_AVG_REG_FETCH_ERROR,
  GETDAILY_FREQUENCY_FETCH_STARTED,
  GETDAILY_FREQUENCY_FETCH_SUCCESS,
  GETDAILY_FREQUENCY_FETCH_ERROR,
  GETHORSES_PROFILE_FETCH_STARTED,
  GETHORSES_PROFILE_FETCH_SUCCESS,
  GETHORSES_PROFILE_FETCH_ERROR,
  GET_FETCH_ONLINE_STARTED,
  GET_FETCH_ONLINE_SUCCESS,
  GET_FETCH_ONLINE_ERROR
} from "../actions/analytics";

const defaultState = {
  visits: {},
  visitsMarketing: {},
  lastSignUps: [],
  lastSignIns: [],
  frequentUsers: [],
  emailAnalytics: [],
  visitorsStat: [],
  clientsAVGRegion: [],
  onlineNow: 0,
  visitors: [],
  affiliates: {},
  getDaily: [],
  VisitOrclient: [],
  getHorsesProfile: [],
  getDetailedData: [],


  VisitOrclientLoading: false,
  getDailyLoading: false,
  affiliateReceiving: false,
  isReceiving: false,
  lastSignUpsLoading: false,
  lastSignInsLoading: false,
  frequentLoading: false,
  emailLoading: false,
  marketingLoading: false,
  onlineNowLoading: false,
  visitorsStatLoading: false,
  clientsAVGRegionLoading: false,
  getHorsesProfileLoading: false,
};

function analyticsReducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVED_DATA_SUCCESS:
      return Object.assign({}, state, {
        visits: action.payload,
        isReceiving: false,
      });
    case RECEIVING_DATA:
      return Object.assign({}, state, {
        isReceiving: true,
      });

    case SIGNUPS_FETCH_STARTED:
      return Object.assign({}, state, {
        lastSignUpsLoading: true,
      });
    case SIGNUPS_FETCH_SUCCESS:
      // console.log(action.payload.data)
      return Object.assign({}, state, {
        lastSignUpsLoading: false,
        lastSignUps: action.payload.data,
      });
    case SIGNUPS_FETCH_ERROR:
      return Object.assign({}, state, {
        lastSignUpsLoading: false,
        lastSignUps: [],
      });

    case SIGNINS_FETCH_STARTED:
      return Object.assign({}, state, {
        lastSignInsLoading: true,
      });
    case SIGNINS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        lastSignInsLoading: false,
        lastSignIns: action.payload.data,
      });
    case SIGNINS_FETCH_ERROR:
      return Object.assign({}, state, {
        lastSignInsLoading: false,
        lastSignIns: [],
      });

    case AFFILIATE_FETCH_STARTED:
      return Object.assign({}, state, {
        affiliateReceiving: true,
      });
    case AFFILIATE_FETCH_SUCCESS:
      return Object.assign({}, state, {
        affiliateReceiving: false,
        affiliates: action.payload.data,
      });
    case AFFILIATE_FETCH_ERROR:
      return Object.assign({}, state, {
        affiliateReceiving: false,
        affiliates: {},
      });

    case FREQUENT_FETCH_STARTED:
      return Object.assign({}, state, {
        frequentLoading: true,
      });
    case FREQUENT_FETCH_SUCCESS:
      return Object.assign({}, state, {
        frequentLoading: false,
        frequentUsers: action.payload.data,
      });
    case FREQUENT_FETCH_ERROR:
      return Object.assign({}, state, {
        frequentLoading: false,
        frequentUsers: [],
      });

    case Email_FETCH_STARTED:
      return Object.assign({}, state, {
        emailLoading: true,
      });
    case Email_FETCH_SUCCESS:
      return Object.assign({}, state, {
        emailAnalytics: action.payload.data,
        emailLoading: false,
      });
    case Email_FETCH_ERROR:
      return Object.assign({}, state, {
        emailAnalytics: [],
        emailLoading: false,
      });

    case RECEIVING_MARKETING_STARTED:
      return Object.assign({}, state, {
        marketingLoading: true,
        visitsMarketing: [],
      });
    case RECEIVING_MARKETING_SUCESS:
      return Object.assign({}, state, {
        visitsMarketing: action.payload.data,
        marketingLoading: false,
      });
    case RECEIVING_MARKETING_ERROR:
      return Object.assign({}, state, {
        visitsMarketing: [],
        marketingLoading: false,
      });

    case ONLINENOW_FETCH_STARTED:
      return Object.assign({}, state, {
        onlineNowLoading: true,
        onlineNow: null,
        visitors: [],
      });
    case ONLINENOW_FETCH_SUCCESS:
      return Object.assign({}, state, {
        onlineNowLoading: false,
        onlineNow: action.payload.data,
        visitors: action.payload.visitors,
      });
    case ONLINENOW_FETCH_ERROR:
      return Object.assign({}, state, {
        onlineNowLoading: false,
        onlineNow: null,
        visitors: [],
      });

    case VISITORORCLIENT_FETCH_STARTED:
      return Object.assign({}, state, {
        VisitOrclientLoading: true,
        VisitOrclient: [],
      });
    case VISITORORCLIENT_FETCH_SUCCESS:
      return Object.assign({}, state, {
        VisitOrclientLoading: false,
        VisitOrclient: action?.payload?.data,

      });
    case VISITORORCLIENT_FETCH_ERROR:
      return Object.assign({}, state, {
        VisitOrclientLoading: false,
        VisitOrclient: [],
      });


    case VISITORS_FETCH_STARTED:
      return Object.assign({}, state, {
        visitorsStatLoading: true,
        visitorsStat: [],
      });
    case VISITORS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        visitorsStatLoading: false,
        visitorsStat: action.payload.visitorsStat,
      });
    case VISITORS_FETCH_ERROR:
      return Object.assign({}, state, {
        visitorsStatLoading: false,
        visitorsStat: [],
      });

    case CLIENT_AVG_REG_FETCH_STARTED:
      return Object.assign({}, state, {
        clientsAVGRegionLoading: true,
        clientsAVGRegion: [],
      });
    case CLIENT_AVG_REG_FETCH_SUCCESS:
      return Object.assign({}, state, {
        clientsAVGRegionLoading: false,
        clientsAVGRegion: action.payload.clientAvGStat,
      });
    case CLIENT_AVG_REG_FETCH_ERROR:
      return Object.assign({}, state, {
        clientsAVGRegionLoading: false,
        clientsAVGRegion: [],
      });

    case GET_FETCH_ONLINE_STARTED:
      return Object.assign({}, state, {
        ...state,
        loadingFetch: true,
      });
    case GET_FETCH_ONLINE_SUCCESS:
      console.log(action.payload)
      return Object.assign({}, state, {
        ...state,
        loadingFetch: false,
        FetchOnline: action.payload.rows,
      });
    case GET_FETCH_ONLINE_ERROR:
      return Object.assign({}, state, {
        ...state,
        loadingFetch: false,
        FetchOnline: [],
      });

    // case FETCH_VISITORS_STARTED:
    //   return Object.assign({}, state, {
    //     ...state,
    //     fetchVisitorsLoading: true,
    //   });
    // case FETCH_VISITORS_SUCCESS:
    //   console.log(action.payload)
    //   return Object.assign({}, state, {
    //     ...state,
    //     fetchVisitorsLoading: false,
    //     fetchVisitors: action.payload.rows,
    //   });
    // case FETCH_VISITORS_ERROR:
    //   return Object.assign({}, state, {
    //     ...state,
    //     fetchVisitorsLoading: false,
    //     fetchVisitorsLoading: [],
    //   });

    case GETDAILY_FREQUENCY_FETCH_STARTED:
      return Object.assign({}, state, {
        getDailyLoading: true,
        getDaily: [],
      });
    case GETDAILY_FREQUENCY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        getDailyLoading: false,
        getDaily: action.payload.getDaily,
      });
    case GETDAILY_FREQUENCY_FETCH_ERROR:
      return Object.assign({}, state, {
        getDailyLoading: false,
        getDaily: [],
      });

    case GETHORSES_PROFILE_FETCH_STARTED:
      return Object.assign({}, state, {
        getHorsesProfileLoading: true,
        getHorsesProfile: [],
        getDetailedData: [],
      });
    case GETHORSES_PROFILE_FETCH_SUCCESS:
      // console.log(action.payload)
      return Object.assign({}, state, {
        getHorsesProfileLoading: false,
        getHorsesProfile: action.payload?.getHorsesProfile,
        getDetailedData: action.payload?.getDetailedData
      });
    case GETDAILY_FREQUENCY_FETCH_ERROR:
      return Object.assign({}, state, {
        getHorsesProfileLoading: false,
        getHorsesProfile: [],
        getDetailedData: [],
      });




    default:
      return state;
  }
}

export default analyticsReducer;
