import { doFetchOnline, getDailyFrequencyUsers, getUsersActivity, getUsersFrequency } from '../../config/config';
import { toast } from 'react-toastify';

export const USERS_ACTIVITY_FETCH_STARTED = 'USERS_ACTIVITY_FETCH_STARTED';
export const USERS_ACTIVITY_FETCH_SUCCESS = 'USERS_ACTIVITY_FETCH_SUCCESS';
export const USERS_ACTIVITY_FETCH_ERROR = 'USERS_ACTIVITY_FETCH_ERROR';

export const USERS_FREQUENCY_FETCH_STARTED = 'USERS_FREQUENCY_FETCH_STARTED';
export const USERS_FREQUENCY_FETCH_SUCCESS = 'USERS_FREQUENCY_FETCH_SUCCESS';
export const USERS_FREQUENCY_FETCH_ERROR = 'USERS_FREQUENCY_FETCH_ERROR';


// get daily frequency users
export const GETDAILY_FREQUENCY_FETCH_STARTED = 'GETDAILY_FREQUENCY_FETCH_STARTED'
export const GETDAILY_FREQUENCY_FETCH_SUCCESS = 'GETDAILY_FREQUENCY_FETCH_SUCCESS'
export const GETDAILY_FREQUENCY_FETCH_ERROR = 'GETDAILY_FREQUENCY_FETCH_ERROR'



function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: (id, date, staffIncluded) => async (dispatch) => {
    try {
      let filter
      let keepPagination = false
      dispatch({
        type: USERS_ACTIVITY_FETCH_STARTED,
        payload: { filter, keepPagination },
      });
      let response
      if (id === undefined) { response = await getUsersActivity({ client_id: 'null', date: (date ? date : 'null'), staffIncluded: staffIncluded }) }
      else { response = await getUsersActivity({ client_id: id, date: (date ? date : 'null'), staffIncluded: staffIncluded }) }

      dispatch({
        type: USERS_ACTIVITY_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: USERS_ACTIVITY_FETCH_ERROR,
      });
    }
  },

  doFetchNoLoading: (id, date, staffIncluded) => async (dispatch) => {
    try {
      let response
      if (id === undefined) { response = await getUsersActivity({ client_id: 'null', date: (date ? date : 'null'), staffIncluded: staffIncluded }) }
      else { response = await getUsersActivity({ client_id: id, date: (date ? date : 'null'), staffIncluded: staffIncluded }) }

      dispatch({
        type: USERS_ACTIVITY_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: USERS_ACTIVITY_FETCH_ERROR,
      });
    }
  },

  doFetchFrequency: (date) => async (dispatch) => {
    try {
      dispatch({
        type: USERS_FREQUENCY_FETCH_STARTED
      });
      let response = await getUsersFrequency({ date: (date ? date : 'null') })

      dispatch({
        type: USERS_FREQUENCY_FETCH_SUCCESS,
        payload: { rows: response },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))
      dispatch({
        type: USERS_FREQUENCY_FETCH_ERROR,
      });
    }
  },

  getDailyFrequencyUsers: () => async (dispatch) => {
    try {
      dispatch({
        type: GETDAILY_FREQUENCY_FETCH_STARTED,
      });
      let response = await getDailyFrequencyUsers()
      dispatch({
        type: GETDAILY_FREQUENCY_FETCH_SUCCESS,
        payload: {
          getDaily: response,
        },

      });
    } catch (error) {
      toast.error('ERROR GETTING daily frequency users')
      dispatch({
        type: GETDAILY_FREQUENCY_FETCH_ERROR,
      });
    }
  },



};


export default actions;
