import { ADMINS_LIST_FETCH_STARTED, ADMINS_LIST_FETCH_SUCCESS, ADMINS_LIST_FETCH_ERROR,
          ADMINS_LIST_DELETE_STARTED, ADMINS_LIST_DELETE_SUCCESS,
          ADMINS_LIST_DELETE_ERROR, ADMINS_LIST_OPEN_CONFIRM, ADMINS_LIST_CLOSE_CONFIRM,
          STAFF_LIST_FETCH_STARTED, STAFF_LIST_FETCH_SUCCESS, STAFF_LIST_FETCH_ERROR} from '../actions/adminListActions';


const defaultState = {
  rows: [],
  staff: [],
  loading: false,
};

function adminReducer(state = defaultState, action) {
  switch (action.type) {
    case ADMINS_LIST_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case ADMINS_LIST_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: action.payload.rows,
      });
    case ADMINS_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: [],
      });

    case ADMINS_LIST_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case ADMINS_LIST_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case ADMINS_LIST_DELETE_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });

    case ADMINS_LIST_OPEN_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: true,
        idToDelete: action.payload.id
      });
    case ADMINS_LIST_CLOSE_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });

    case STAFF_LIST_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case STAFF_LIST_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        staff: action.payload.staff,
      });
    case STAFF_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        staff: [],
      });

    default:
      return state;
  }
}

export default adminReducer;