import { getTopTracks,getResultsUserPref,getTracksBasedOnDate,getHJT, getResultsUserRodPref } from '../../config/config';
import { toast } from 'react-toastify'

export const RACES_ANALYTICS_STARTED = 'RACES_ANALYTICS_STARTED'
export const RACES_ANALYTICS_SUCCESS = 'RACES_ANALYTICS_SUCCESS'
export const RACES_ANALYTICS_ERROR = 'RACES_ANALYTICS_ERROR'
export const RESULTS_ANALYTICS_STARTED = 'RESULTS_ANALYTICS_STARTED'
export const RESULTS_ANALYTICS_SUCCESS = 'RESULTS_ANALYTICS_SUCCESS'
export const RESULTS_ANALYTICS_ERROR = 'RESULTS_ANALYTICS_ERROR'
export const RESULTS_ANALYTICS_ROD_STARTED = 'RESULTS_ANALYTICS_ROD_STARTED'
export const RESULTS_ANALYTICS_ROD_SUCCESS = 'RESULTS_ANALYTICS_ROD_SUCCESS'
export const RESULTS_ANALYTICS_ROD_ERROR = 'RESULTS_ANALYTICS_ROD_ERROR'
export const TRACKS_SELECTED_STARTED = 'TRACKS_SELECTED_STARTED'
export const TRACKS_SELECTED_SUCCESS = 'TRACKS_SELECTED_SUCCESS'
export const TRACKS_SELECTED_ERROR = 'TRACKS_SELECTED_ERROR'
export const HJT_SELECTED_STARTED = 'HJT_SELECTED_STARTED'
export const HJT_SELECTED_SUCCESS = 'HJT_SELECTED_SUCCESS'
export const HJT_SELECTED_ERROR = 'HJT_SELECTED_ERROR'

const racesActions = {

    getTopTracks: (months) => async (dispatch) => {
        try {
          dispatch({
            type: RACES_ANALYTICS_STARTED,
          });
          let response = await getTopTracks(months)
          dispatch({
            type: RACES_ANALYTICS_SUCCESS,
            payload: {
                data: response,
              },
          });
        } catch (error) {
          toast.error('ERROR GETTING TOP WIN % TRACK OF ALL TIME'+error)
          dispatch({
            type: RACES_ANALYTICS_ERROR,
          });
        }
    },  
    getResultsUser: (data) => async (dispatch) => {
      try {
        dispatch({
          type: RESULTS_ANALYTICS_STARTED,
        });
        let response = await getResultsUserPref(data)
        dispatch({
          type: RESULTS_ANALYTICS_SUCCESS,
          payload: {
              data: response,
            },
        });
      } catch (error) {
        toast.error('ERROR GETTING venues BASED ON USER PREF'+error)
        dispatch({
          type: RESULTS_ANALYTICS_ERROR,
        });
      }
  },

  testAction: () => async (dispatch) => {
    toast.error('ERROR GETTING venues BASED ON USER PREF');
  },
  getResultsUserRod: (data) => async (dispatch) => {
    try {
      dispatch({
        type: RESULTS_ANALYTICS_ROD_STARTED,
      });
      let response = await getResultsUserRodPref(data)
      dispatch({
        type: RESULTS_ANALYTICS_ROD_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING venues BASED ON USER PREF' + error)
      dispatch({
        type: RESULTS_ANALYTICS_ROD_ERROR,
      });
    }
  },
  getTracksUser: (data) => async (dispatch) => {
    try {
      dispatch({
        type: TRACKS_SELECTED_STARTED,
      });
      let response = await getTracksBasedOnDate(data)
      dispatch({
        type: TRACKS_SELECTED_SUCCESS,
        payload: {
            data: response,
          },
      });
    } catch (error) {
      toast.error('ERROR GETTING ALL RESULTS BASED ON USER PREF'+error)
      dispatch({
        type: TRACKS_SELECTED_ERROR,
      });
    }
}, 

getHJTUser: (data) => async (dispatch) => {
  try {
    dispatch({
      type: HJT_SELECTED_STARTED,
    });
    let response = await getHJT(data)
    dispatch({
      type: HJT_SELECTED_SUCCESS,
      payload: {
          data: response,
        },
    });
  } catch (error) {
    toast.error('ERROR GETTING ALL RESULTS BASED ON USER PREF'+error)
    dispatch({
      type: HJT_SELECTED_ERROR,
    });
  }
}, 
  };

export default racesActions;



