import { getUsers, getUsersDate, deleteUser } from '../../config/config';
import { toast } from 'react-toastify';

export const USERS_LIST_FETCH_STARTED = 'USERS_LIST_FETCH_STARTED';
export const USERS_LIST_FETCH_SUCCESS = 'USERS_LIST_FETCH_SUCCESS';
export const USERS_LIST_FETCH_ERROR = 'USERS_LIST_FETCH_ERROR';
export const USERS_LIST_DELETE_STARTED = 'USERS_LIST_DELETE_STARTED';
export const USERS_LIST_DELETE_SUCCESS = 'USERS_LIST_DELETE_SUCCESS';
export const USERS_LIST_DELETE_ERROR = 'USERS_LIST_DELETE_ERROR';
export const USERS_LIST_OPEN_CONFIRM = 'USERS_LIST_OPEN_CONFIRM';
export const USERS_LIST_CLOSE_CONFIRM = 'USERS_LIST_CLOSE_CONFIRM';
export const USERS_DATE_FETCH_STARTED = 'USERS_DATE_FETCH_STARTED';
export const USERS_DATE_FETCH_SUCCESS = 'USERS_DATE_FETCH_SUCCESS';
export const USERS_DATE_FETCH_ERROR = 'USERS_DATE_FETCH_ERROR';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: (filter, keepPagination = false) => async (dispatch) => {
      try {
        dispatch({
          type: USERS_LIST_FETCH_STARTED,
          payload: { filter, keepPagination },
        });

        const response = await getUsers()

        dispatch({
          type: USERS_LIST_FETCH_SUCCESS,
          payload: {
            rows: response,
          },
        });
      } catch (error) {
        toast.error(selectErrorMessage(error))

        dispatch({
          type: USERS_LIST_FETCH_ERROR,
        });
      }
  },

  doDelete: (id) => async (dispatch) => {
      try {
        dispatch({
          type: USERS_LIST_DELETE_STARTED,
        });
  
        await deleteUser({'client_id': id}) /*add later on to handle if their is an error*/
  
        dispatch({
          type: USERS_LIST_DELETE_SUCCESS,
        });
  
        const response = await getUsers()
        dispatch({
          type: USERS_LIST_FETCH_SUCCESS,
          payload: {
            rows: response,
          },
        });
  
      } catch (error) {
        toast.error(selectErrorMessage(error))
  
        dispatch({
          type: USERS_LIST_DELETE_ERROR,
        });
      }
  },

  doOpenConfirm: (id) => async (dispatch) => {
      dispatch({
        type: USERS_LIST_OPEN_CONFIRM,
        payload: {
          id: id
        },
      });
  },

  doCloseConfirm: () => async (dispatch) => {
      dispatch({
        type: USERS_LIST_CLOSE_CONFIRM,
      });
  },


  doGetUserRegistereDate: (date) => async (dispatch) =>{
    try {
      dispatch({
        type: USERS_DATE_FETCH_STARTED,
      })

      const response = await getUsersDate({date: date})

      dispatch({
        type: USERS_DATE_FETCH_SUCCESS,
        payload: {
          rows: response.data,
        },
      });

    } catch(err){
      toast.error('Something Weent Wrong, Please contact support')
      dispatch({
        type: USERS_DATE_FETCH_ERROR,
      })
    }
  }
};


export default actions;
