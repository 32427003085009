import { getAdmin, createAdmin, updateAdmin} from '../../config/config'
//import { doInit } from 'actions/auth'
import { toast } from 'react-toastify'
//import config from '../config'
//import { mockUser } from '../actions/mock'

export const ADMINS_FORM_RESET = 'ADMINS_FORM_RESET';
export const ADMINS_FORM_FIND_SUCCESS = 'ADMINS_FORM_FIND_SUCCESS';
export const ADMINS_FORM_FIND_STARTED = 'ADMINS_FORM_FIND_STARTED';
export const ADMINS_FORM_FIND_ERROR = 'ADMINS_FORM_FIND_ERROR';
export const ADMINS_FORM_CREATE_STARTED = 'ADMINS_FORM_CREATE_STARTED';
export const ADMINS_FORM_CREATE_SUCCESS = 'ADMINS_FORM_CREATE_SUCCESS';
export const ADMINS_FORM_CREATE_ERROR = 'ADMINS_FORM_CREATE_ERROR';
export const ADMINS_FORM_UPDATE_STARTED = 'ADMINS_FORM_UPDATE_STARTED';
export const ADMINS_FORM_UPDATE_SUCCESS = 'ADMINS_FORM_UPDATE_SUCCESS';
export const ADMINS_FORM_UPDATE_ERROR = 'ADMINS_FORM_UPDATE_ERROR';
export const ADMINS_PASSWORD_UPDATE_SUCCESS = 'ADMINS_PASSWORD_UPDATE_SUCCESS';


function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return JSON.stringify(data.message);
  }

  return error.message || 'ERROR';
}

const actions = {
  doNew: () => {
    return {
      type: ADMINS_FORM_RESET,
    };
  },

  doFind: (id, history) => async (dispatch) => {
      try {
        dispatch({
          type: ADMINS_FORM_FIND_STARTED,
        });
        await getAdmin({'admin_id': id}).then(res => {
          const record = res.data[0];
  
          dispatch({
            type: ADMINS_FORM_FIND_SUCCESS,
            payload: record,
          });
        })
      } catch (error) {
        toast.error(selectErrorMessage(error))  
        dispatch({
          type: ADMINS_FORM_FIND_ERROR,
        });
  
        history.push('/admin/staff_list');
      }
  },
 
  doCreate: (values, history) => async (dispatch) => {
    try {
      dispatch({
        type: ADMINS_FORM_CREATE_STARTED,
      });
      await createAdmin(values).then(res => {

        dispatch({
          type: ADMINS_FORM_CREATE_SUCCESS,
        });
        toast.success('Admin created successfully');
        history.push('/admin/staff_list');
      })
    } catch (error) {
      toast.error(selectErrorMessage(error))
      dispatch({
        type: ADMINS_FORM_CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values, history) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: ADMINS_FORM_UPDATE_STARTED,
      });
      updateAdmin({'admin_id': id, data: values}).then(res => {
        dispatch({
          type: ADMINS_FORM_UPDATE_SUCCESS,
        });
        toast.success('Admin updated');
        history.push('/admin/staff_list');
      })     
    } catch (error) {
      toast.error(selectErrorMessage(error)) 

      dispatch({
        type: ADMINS_FORM_UPDATE_ERROR,
      });
    }
  },
};

export default actions;
