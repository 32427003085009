import {
  USERSSP_FORM_RESET,
  USERSSP_FORM_FIND_SUCCESS,
  USERSSP_FORM_FIND_SECOND_SUCCESS,
  USERSSP_FORM_FIND_STARTED,
  USERSSP_FORM_FIND_ERROR,
  USERSSP_FORM_CREATE_STARTED,
  USERSSP_FORM_CREATE_SUCCESS,
  USERSSP_FORM_CREATE_ERROR,
  USERSSP_FORM_UPDATE_STARTED,
  USERSSP_FORM_UPDATE_SUCCESS,
  USERSSP_FORM_UPDATE_ERROR,
} from "../actionsSP/usersFormActions";

const defaultState = {
  findLoading: false,
  saveLoading: false,
  record: null,
  recordA: [],
};

function userFormReducerSP(state = defaultState, action) {
  switch (action.type) {
    case USERSSP_FORM_RESET:
      return Object.assign({}, state, {
        ...defaultState,
      });
    case USERSSP_FORM_FIND_STARTED:
      return Object.assign({}, state, {
        ...state,
        record: null,
        recordA: [],
        findLoading: true,
      });
    case USERSSP_FORM_FIND_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        record: action.payload,
        findLoading: false,
      });
    case USERSSP_FORM_FIND_SECOND_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        recordA: action?.payload,
        findLoading: false,
      });
    case USERSSP_FORM_FIND_ERROR:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: false,
      });
    case USERSSP_FORM_CREATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case USERSSP_FORM_CREATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case USERSSP_FORM_CREATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case USERSSP_FORM_UPDATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case USERSSP_FORM_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case USERSSP_FORM_UPDATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    default:
      return state;
  }
}

export default userFormReducerSP;
