import {
  LEADSSP_FORM_RESET, LEADSSP_FORM_FIND_SUCCESS, LEADSSP_FORM_FIND_STARTED, LEADSSP_FORM_FIND_ERROR, LEADSSP_FORM_CREATE_STARTED,
  LEADSSP_FORM_CREATE_SUCCESS, LEADSSP_FORM_CREATE_ERROR, LEADSSP_FORM_UPDATE_STARTED, LEADSSP_FORM_UPDATE_SUCCESS,
  LEADSSP_FORM_UPDATE_ERROR
} from '../actionsSP/leadsFormActions';

const defaultState = {
  findLoading: false,
  saveLoading: false,
  record: null,
};

function leadFormReducerSP(state = defaultState, action) {
  switch (action.type) {
    case LEADSSP_FORM_RESET:
      return Object.assign({}, state, {
        ...defaultState,
      });
    case LEADSSP_FORM_FIND_STARTED:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: true,
      });
    case LEADSSP_FORM_FIND_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        record: action.payload,
        findLoading: false,
      });
    case LEADSSP_FORM_FIND_ERROR:
      return Object.assign({}, state, {
        ...state,
        record: null,
        findLoading: false,
      });
    case LEADSSP_FORM_CREATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case LEADSSP_FORM_CREATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case LEADSSP_FORM_CREATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case LEADSSP_FORM_UPDATE_STARTED:
      return Object.assign({}, state, {
        ...state,
        saveLoading: true,
      });
    case LEADSSP_FORM_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    case LEADSSP_FORM_UPDATE_ERROR:
      return Object.assign({}, state, {
        ...state,
        saveLoading: false,
      });
    default:
      return state;
  }
};


export default leadFormReducerSP;