import { USERSSP_LIST_FETCH_STARTED, USERSSP_LIST_FETCH_SUCCESS, USERSSP_LIST_FETCH_ERROR, USERSSP_LIST_DELETE_STARTED, USERSSP_LIST_DELETE_SUCCESS,
          USERSSP_LIST_DELETE_ERROR, USERSSP_LIST_OPEN_CONFIRM, USERSSP_LIST_CLOSE_CONFIRM } from '../actionsSP/usersListActions';


const defaultState = {
  rows: [],
  loading: false,
};

function userReducerSP(state = defaultState, action) {
  switch (action.type) {
    case USERSSP_LIST_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case USERSSP_LIST_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: action.payload.rows,
      });
    case USERSSP_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: [],
      });
    case USERSSP_LIST_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case USERSSP_LIST_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case USERSSP_LIST_DELETE_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case USERSSP_LIST_OPEN_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: true,
        idToDelete: action.payload.id
      });
    case USERSSP_LIST_CLOSE_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    default:
      return state;
  }
}

export default userReducerSP;