import {
  getAllVenues, deleteVenueDB, updateVenueDB, createVenueDB, getSelectedVenue,
  getvenueInfo, updateTrackCond, updateRaceInfo, doFindAllInfo, updateRaceDetails,
  updateHorseInfo, loadVenues, getLastRailPos, listVenueComments, loadVenuesWithMaps,updateRaceStatus
} from '../../config/config';
import { toast } from 'react-toastify'

export const VENUES_ANALYTICS_STARTED = 'VENUES_ANALYTICS_STARTED'
export const VENUES_ANALYTICS_SUCCESS = 'VENUES_ANALYTICS_SUCCESS'
export const VENUES_ANALYTICS_ERROR = 'VENUES_ANALYTICS_ERROR'

export const VENUES_SELECTEDDATE_STARTED = 'VENUES_SELECTEDDATE_STARTED'
export const VENUES_SELECTEDDATE_SUCCESS = 'VENUES_SELECTEDDATE_SUCCESS'
export const VENUES_SELECTEDDATE_ERROR = 'VENUES_SELECTEDDATE_ERROR'

export const VENUE_EDITING_STARTED = 'VENUE_EDITING_STARTED'
export const VENUE_EDITING_SUCCESS = 'VENUE_EDITING_SUCCESS'
export const VENUE_EDITING_ERROR = 'VENUE_EDITING_ERROR'

export const FIND_RACE_INFO_STARTED = 'FIND_RACE_INFO_STARTED'
export const FIND_RACE_INFO_SUCCESS = 'FIND_RACE_INFO_SUCCESS'
export const FIND_RACE_INFO_ERROR = 'FIND_RACE_INFO_ERROR'

export const LAST_RAIL_POS_STARTED = 'LAST_RAIL_POS_STARTED'
export const LAST_RAIL_POS_SUCCESS = 'LAST_RAIL_POS_SUCCESS'
export const LAST_RAIL_POS_ERROR = 'LAST_RAIL_POS_ERROR'

export const LIST_VENUE_COMMENTS_STARTED = 'LIST_VENUE_COMMENTS_STARTED'
export const LIST_VENUE_COMMENTS_SUCCESS = 'LIST_VENUE_COMMENTS_SUCCESS'
export const LIST_VENUE_COMMENTS_ERROR = 'LIST_VENUE_COMMENTS_ERROR'

export const LOAD_VENUES = 'LOAD_VENUES'
export const LOAD_VENUES_WITH_MAPS = 'LOAD_VENUES_WITH_MAPS';

const venuesActions = {

  getVenues: () => async (dispatch) => {
    try {
      dispatch({
        type: VENUES_ANALYTICS_STARTED,
      });
      let response = await getAllVenues()
      dispatch({
        type: VENUES_ANALYTICS_SUCCESS,
        payload: {
          data: response,
        },
      });
    } catch (error) {
      toast.error('ERROR GETTING ALL VENUES INFORMATION')
      dispatch({
        type: VENUES_ANALYTICS_ERROR,
      });
    }
  },

  loadAllVenues: () => async (dispatch) => {
    try{
      let response = await loadVenues();
      dispatch({
        type: LOAD_VENUES,
        payload: {
          data: response,
        },
      })
    }catch(error){
      toast.error('ERROR GETTING VENUES');
    }
  },

  loadVenuesWM: () => async (dispatch) =>{
    try{
      let response = await loadVenuesWithMaps();
      dispatch({
        type: LOAD_VENUES_WITH_MAPS,
        payload:{
          data: response,
        }
      })
    }catch(error){
      toast.error('ERROR GETTING VENUES');
    }
  },

  deleteVenue: (id) => async (dispatch) => {
    try {
      await deleteVenueDB({ venueID: id })
      toast.success('VENUE DELETED SUCCESSFULLY')
    } catch (error) {
      toast.error('ERROR DELETING VENUE')
    }
  },

  updateVenue: (data) => async (dispatch) => {
    try {
      await updateVenueDB(data)
      toast.success('VENUE UPDATED SUCCESSFULLY')
    } catch (error) {
      toast.error('ERROR UPDATING VENUE')
    }
  },

  doCreateVenue: (data) => async (dispatch) => {
    try {
      await createVenueDB(data)
      toast.success('NEW VENUE ADDED SUCCESSFULLY')
    } catch (error) {
      toast.error('ERROR ADDING NEW VENUE')
    }
  },

  getSelectedVenue: (certainDate) => async (dispatch) => {
    try {
      dispatch({
        type: VENUES_SELECTEDDATE_STARTED,
      });
      let response =  await getSelectedVenue({selectedDate : certainDate})
      dispatch({
        type: VENUES_SELECTEDDATE_SUCCESS,
        payload: {
          data: response,
        },
      })
    } catch (error) {
      toast.error('ERROR GETTING VENUE FOR DATE: ' + certainDate)
      dispatch({
        type: VENUES_SELECTEDDATE_ERROR,
      })
    }
  },

  venueInfo:(selectedDay, selectedVenue) => async (dispatch) => {
    try {
      dispatch({
        type: VENUE_EDITING_STARTED,
      });
      let res =  await getvenueInfo({selectedDate : selectedDay, selectedVenue: selectedVenue})
      
      dispatch({
        type: VENUE_EDITING_SUCCESS,
        payload: {
          venueInfo: res.data.info,
          selectedDate: res.data.selectedDate,
          selectedVenue : res.data.selectedVenue,
        },
      })

    } catch (error) {
      toast.error('ERROR EDITING VENUE. PLEASE TRY AGAIN OR REFRESH THE PAGE')
      dispatch({
        type: VENUE_EDITING_ERROR,
      })
    }
  },

  lastRailPos: (data) => async (dispatch) =>{
    try{
      dispatch({type: LAST_RAIL_POS_STARTED})
      let response = await getLastRailPos(data);
      dispatch({
        type: LAST_RAIL_POS_SUCCESS,
        payload: {
          railPos: response,
        }
      })
    }catch(error){
      toast.error('ERROR LOADING RAIL POS.')
      dispatch({type: LAST_RAIL_POS_ERROR})
    }
  },

  listVenueComments: (data) => async (dispatch) => {
    try{
      dispatch({type: LIST_VENUE_COMMENTS_STARTED});
      let response = await listVenueComments(data);
      dispatch({
        type: LIST_VENUE_COMMENTS_SUCCESS,
        payload:{
          venueCmts: response,
        }
      })
    }catch(error){
      toast.error('ERROR LOADING VENUE COMMENTS.');
      dispatch({type: LIST_VENUE_COMMENTS_ERROR});
    }
  },

  updateTrackCond:(selected, trackCondition, NA) => async (dispatch) => {
    try {
      await updateTrackCond({selected_races : selected, trackCondition: trackCondition, NA: NA})
      toast.success('UPDATED SUCCESSFULLY')
    } catch (error) {
      toast.error('ERROR UPDATING TRACK CONDITION. PLEASE TRY AGAIN')
    }
  },

  updateRaceInfo:(data) => async (dispatch) => {
    try {
      await updateRaceInfo({data : data})
      toast.success('ALL INFO UPDATED SUCCESSFULLY')
    } catch (error) {
      toast.error('ERROR UPDATING INFO. PLEASE TRY AGAIN')
    }
  },

  updateRaceDetails:(id, data, history) => async (dispatch) => {
    try {
      await updateRaceDetails({id : id, data: data})
      toast.success('ALL INFO UPDATED SUCCESSFULLY')
      history.push('/admin/venues');
    } catch (error) {
      toast.error('ERROR UPDATING INFO. PLEASE TRY AGAIN')
    }
  },

  doFindAllInfo:(raceId) => async (dispatch) => {
    try {
      dispatch({
        type: FIND_RACE_INFO_STARTED,
      });
      let response =  await doFindAllInfo({raceId : raceId})
      
      dispatch({
        type: FIND_RACE_INFO_SUCCESS,
        payload: {
          raceInfo: response.raceInfo[0],
          horsesInfo: response.horsesInfo
        },
      })
    } catch (error) {
      toast.error('ERROR GETTING RACE INFORMATIONS. PLEASE TRY AGAIN')
      dispatch({
        type: FIND_RACE_INFO_ERROR,
      })
    }
  },

  updateHorseInfo:(data) => async (dispatch) => {
    try {
      await updateHorseInfo({data : data})
      toast.success('ALL HORSE INFO UPDATED SUCCESSFULLY')
    } catch (error) {
      toast.error('ERROR UPDATING HORSE INFO. PLEASE TRY AGAIN')
    }
  },


  updateRaceStatus:(data) => async (dispatch) => {
    try {
      await updateRaceStatus(data).then(response=>{
        if(response?.data?.status===200){
          toast.success('Race Status UPDATED SUCCESSFULLY')
        }else{
          toast.error('ERROR UPDATING Race Status. PLEASE TRY AGAIN')
        }
      })
    } catch (error) {
      toast.error('ERROR UPDATING Race Status. PLEASE TRY AGAIN')
    }
  },

};

export default venuesActions;



