import { toast } from "react-toastify";
import { getAllUserActivity } from "../../config/configSP";

export const USERS_IP_LIST_FETCH_STARTED = "USERS_IP_LIST_FETCH_STARTED";
export const USERS_IP_LIST_FETCH_SUCCESS = "USERS_IP_LIST_FETCH_SUCCESS";
export const USERS_IP_LIST_FETCH_ERROR = "USERS_IP_LIST_FETCH_ERROR";

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return JSON.stringify(data.message);
  }

  return error.message || "ERROR";
}

const userIpActions = {
  getAll: () => async (dispatch) => {
    try {
      dispatch({
        type: USERS_IP_LIST_FETCH_STARTED,
      });
      await getAllUserActivity().then((response) => {
        dispatch({
          type: USERS_IP_LIST_FETCH_SUCCESS,
          payload: response?.data,
        });
      });
    } catch (err) {
      toast.error(selectErrorMessage(err));
      dispatch({
        type: USERS_IP_LIST_FETCH_ERROR,
      });

      // history.push("/admin/smartpunting/users");
    }
  },
};
export default userIpActions;
