import {
  USERS_ACTIVITY_FETCH_STARTED,
  USERS_ACTIVITY_FETCH_SUCCESS,
  USERS_ACTIVITY_FETCH_ERROR,
  USERS_FREQUENCY_FETCH_STARTED,
  USERS_FREQUENCY_FETCH_SUCCESS,
  USERS_FREQUENCY_FETCH_ERROR,
  GETDAILY_FREQUENCY_FETCH_STARTED,
  GETDAILY_FREQUENCY_FETCH_SUCCESS,
  GETDAILY_FREQUENCY_FETCH_ERROR,


} from "../actions/usersActivityActions";

const defaultState = {
  activities: [],
  frequency: [],
  getDaily: [],

  getDailyLoading: false,
  loading: false,
};

function usersActivityReducer(state = defaultState, action) {
  switch (action.type) {
    case USERS_ACTIVITY_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case USERS_ACTIVITY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        activities: action.payload.rows,
      });
    case USERS_ACTIVITY_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        activities: [],
      });

    case USERS_FREQUENCY_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case USERS_FREQUENCY_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        frequency: action.payload.rows,
      });
    case USERS_FREQUENCY_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        frequency: [],
      });

    case GETDAILY_FREQUENCY_FETCH_STARTED:
      return Object.assign({}, state, {
        getDailyLoading: true,
        FetchOnline: [],
      });
    case GETDAILY_FREQUENCY_FETCH_SUCCESS:
      // console.log(action.payload.getDaily)
      return Object.assign({}, state, {
        getDailyLoading: false,
        getDaily: action.payload.getDaily,
      });
    case GETDAILY_FREQUENCY_FETCH_ERROR:
      return Object.assign({}, state, {
        getDailyLoading: false,
        getDaily: [],
      });

    default:
      return state;
  }
}

export default usersActivityReducer;
