import { Redirect, Route } from 'react-router';
import React from 'react';


export const AdminRoute = ({currentUser, dispatch, component, ...rest}) => {
  if (currentUser == null) {
    return (<Redirect to="/login"/>)
  } else {
    return (
      <Route {...rest} render={props => (React.createElement(component, props))}/>
    );
  }
};

export const AuthRoute = ({currentUser, dispatch, component, ...rest}) => {
  const {from} = {from: {pathname: '/admin'}};
  if (currentUser != null) {
    return (
      <Redirect to={from}/>
    );
  } else {
    return (
      <Route {...rest} render={props => (React.createElement(component, props))}/>
    );
  }
};
