import { ADVSEL_LIST_FETCH_STARTED, ADVSEL_LIST_FETCH_SUCCESS, ADVSEL_LIST_FETCH_ERROR, ADVSEL_LIST_DELETE_STARTED, ADVSEL_LIST_DELETE_SUCCESS,
          ADVSEL_LIST_DELETE_ERROR, ADVSEL_LIST_OPEN_CONFIRM, ADVSEL_LIST_CLOSE_CONFIRM } from '../actions/advSelListActions';


const defaultState = {
  rows: [],
  loading: false,
};

function advSelReducer(state = defaultState, action) {
  switch (action.type) {
    case ADVSEL_LIST_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case ADVSEL_LIST_FETCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: action.payload.rows,
      });
    case ADVSEL_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: [],
      });
    case ADVSEL_LIST_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case ADVSEL_LIST_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case ADVSEL_LIST_DELETE_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case ADVSEL_LIST_OPEN_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: true,
        idToDelete: action.payload.id
      });
    case ADVSEL_LIST_CLOSE_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    default:
      return state;
  }
}

export default advSelReducer;